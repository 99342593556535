import React from 'react';
import { Link } from 'gatsby';

import ActivityBreadCrumb from '../../components/ActivityBreadCrumbs';
import Layout from '../../components/layout';
import Seo from '../../components/Seo';
import ShareYouThoughts from '../../components/ShareYouThoughts';

const Mission2 = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Mission two',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pt-8 pb-8 grey-overlay">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pb-lg-0">
              <h1 className="h1-two-line-v2 green mb-12 mb-lg-16 mt-8">
                <span>Mission 2</span>
              </h1>
            </div>
            <div className="col-lg-6 pb-4 pb-lg-0">
              <div
                style={{
                  position: 'absolute',
                  top: '50px',
                  left: '450px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(340deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Stars/Star-2.svg"
                  alt="Illustration of asterisks"
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '100px',
                  left: '550px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(20deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  height="100px"
                  src="/images/Doodles/Exclamations/Exclamation-7.svg"
                  alt="Illustration of punctuation"
                />
              </div>
            </div>
          </div>
          {/* Row 1 */}
          <div className="row px-xl-8 gx-3 pb-5">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Drinking water</span>
                  <br />
                  <span>diary</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionTwo/SEW-Drinking-Water-Diary.svg)`,
                      right: '-18px',
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-2/drinking-water-diary"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Make a</span>
                  <br />
                  <span>rain maker</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionTwo/SEW-Make-a-rain-maker.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-2/make-a-rain-maker"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Bring the garden</span>
                  <br />
                  <span>to life</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionTwo/SEW-Bring-the-garden-to-life.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-2/bring-the-garden-to-life"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Row 2 */}
          <div className="row px-xl-8 gx-3 pb-5">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Rain cloud</span>
                  <br />
                  <span>in a jar</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionTwo/SEW-Rain-cloud-in-a-jar.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-2/rain-cloud-in-a-jar"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Scavenger</span>
                  <br />
                  <span>hunt</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionTwo/SEW-Scavenger-hunt.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-2/scavenger-hunt"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Quiz!</span>
                  <br />
                  <span />
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionTwo/SEW-Mission-02-Quiz.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-2/quiz"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ShareYouThoughts />
  </Layout>
);

export default Mission2;
